import Trumbowyg from 'trumbowyg';
import '../../../node_modules/trumbowyg/plugins/cleanpaste/trumbowyg.cleanpaste';
import '../../../node_modules/trumbowyg/dist/ui/trumbowyg.css';
$.trumbowyg.svgPath = iconsUrl;

let trumbowyg;

export default {
  trumbowyg() {
    trumbowyg = $('#comment-container').trumbowyg({
      plugins: {
        allowTagsFromPaste: {
            allowedTags: ['h1', 'h2', 'h3', 'h4', 'p', 'br', 'span', 'strong', 'italic', 'a', 'ul', 'ol', 'li']
        }
      },
      removeformatPasted: true,
      tagsToRemove: ['script', 'link', 'input', ],
      btnsDef: {
        styles: {
            dropdown: ['strong', 'em', 'removeformat'],
            ico: 'strong'
        }
      },
      btns: [
        ['viewHTML'],
        ['styles']
      ],
      autogrow: true
    });

  },
  init() {
    const $self = this;
    let holder = $('#holder');
    let commentBlock = $("#make-comment");

    $('#only_invoices').on('change', function() {
      $('.messages-item:not(.invoice)').toggle('fast');
    });

    $("body").on("click", ".messages-item", (e) => {
      $('#messages-list').addClass('disabled');
      let targetId = $(e.currentTarget).data('msg-id');
      let commentsOnOff = $(e.currentTarget).data('comments-on');
      $('.messages-item').removeClass('messages-item--active');
      $(e.currentTarget).addClass('messages-item--active');
      $('[name="notification_id"]').val(targetId);
      resetCommentBlock();
      if(commentsOnOff == true) {
        commentBlock.fadeIn('slow');
      } else {
        commentBlock.hide();
      }
      $self.sendToAPI(targetId, $(e.currentTarget), countUnread);
    });

    if( $(window).width() <= 768) {
      let targetToOpen = $('.message-main');
      let targetToOpenTitle = $('#mob-hero__title');
      let goBack = targetToOpenTitle.find('.go-back');
      $("body").on("click", ".messages-item", (e) => {
        let targetTitle = $(e.currentTarget).find('.title').html();
        targetToOpenTitle.html(targetTitle);
        targetToOpen.addClass('opened');
      });

      $("body").on("click", ".go-back", (e) => {
        targetToOpen.removeClass('opened');
      });
    }

    
    let countUnread = () => {
      let number = $('#messages-list').find('.unread').length;
      holder.attr('data-newmsgs', number);

      if(number == 0) {
        $('#holder').addClass('title--noNew');
      }
    };
    countUnread();

    $("[data-async-comment]").on('submit', (e) => {
      e.preventDefault();
      const $self = this;
      $('#make-comment').addClass('disabled');

      $.ajax({
        url: e.target.dataset.action,
        data: {
          notification_id: $('[name="notification_id"]').attr('value'),
          user_id: $('[name="user_id"]').attr('value'),
          comment_content: trumbowyg.trumbowyg('html')
        },
        type: e.target.dataset.method,
        success(resp) {
          resetCommentBlock();
          $self.handleResponse(resp, e.target);
          $('#make-comment').removeClass('disabled');
        }
      });
    });

    function resetCommentBlock() {
      trumbowyg.trumbowyg('empty');
      $('#attached-file-name').empty();
      $('[data-async-comment]')[0].reset();
    }
  },
  sendToAPI(targetId, item, countUnread) {
    const $self = this;

    $.ajax({
      url: $('#messages-list').data('action'),
      data: {
        notification_id: targetId
      },
      type: $('#messages-list').data('method'),
      beforeSend: function(){
        $('#preloader').fadeIn('fast');
      },
      complete: function(){
        $('#preloader').hide();
      },
      success(resp) {
        checkUnreaded(item);
        countUnread();
        $self.handleResponse(resp, targetId);
        $('#messages-list').removeClass('disabled');
      }
    });

    function checkUnreaded(item) {
      if(item.hasClass('unread')) {
        item.removeClass('unread');
      }
    }
  },
  handleResponse(resp, targetId) {

    if(resp.status === "success" && resp.create_one_comm === "true") {
      const userBlock = `
        <div class="comment">
          <article class="article">
            <div class="comment-text comment-text--neg">${resp.content}</div>
            <p class="comment-date">${resp.date}</p>
          </article class="article">
        </div>
      `;
      $('#comments-list').append(userBlock).hide().fadeIn('slow');

    } else if(resp.status === "success") {

      if (resp.status === "success") {
        
        $('#comments-files').empty();
        $('#comments-list').empty();

        $('#comments-title').html(resp.title).hide().fadeIn('slow');
        $('#comments-text').html(resp.text).hide().fadeIn('slow');

        if(resp.files) {
          resp.files.forEach(item => {
            const file = `
              <a class="download-file" href="${item.url}" download>${item.name}</a>
            `;

            $('#comments-files').append(file).hide().fadeIn('slow');
          });
        }

        if(resp.comments) {
          resp.comments.forEach(comment => {
            const adminBlock = `
              <div class="comment comment--admin">
                <div class="img">${resp.initials}</div>
                <article class="article">
                  <div class="comment-text">${comment.content}</div>
                  <p class="comment-date">${comment.date}</p>
                </article class="article">
              </div>
            `;
            const userBlock = `
              <div class="comment">
                <article class="article">
                  <div class="comment-text comment-text--neg">${comment.content}</div>
                  <p class="comment-date">${comment.date}</p>
                </article class="article">
              </div>
            `;

            if(comment.user == 'admin') {
              $('#comments-list').append(adminBlock).hide().fadeIn('slow');
            } else {
              $('#comments-list').append(userBlock).hide().fadeIn('slow');
            }
          });
        }
        
      } else if(resp.status === "error") {
        console.log(resp.message);
      }
    } else if(resp.status === "error") {
      console.log(resp.message);
    }
  }
}
