export default {
  init() {
    $('.contacts__mapBlock').ready(function() {
      var mapsOptions = {
        zoom: 15,
        panControl: false,
        rotateControl: false,
        scaleControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        scrollwheel: false,
        zoomControl: false,
        styles: [
          {
              "featureType": "all",
              "elementType": "labels.text.fill",
              "stylers": [
                  {
                      "saturation": 36
                  },
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 40
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "labels.text.stroke",
              "stylers": [
                  {
                      "visibility": "on"
                  },
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 16
                  }
              ]
          },
          {
              "featureType": "all",
              "elementType": "labels.icon",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "administrative",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 20
                  }
              ]
          },
          {
              "featureType": "administrative",
              "elementType": "geometry.stroke",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 17
                  },
                  {
                      "weight": 1.2
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "geometry",
              "stylers": [
                  {
                      "lightness": 20
                  },
                  {
                      "color": "#202020"
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "geometry",
              "stylers": [
                  {
                      "lightness": 21
                  },
                  {
                      "color": "#202020"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "geometry.fill",
              "stylers": [
                  {
                      "lightness": 17
                  },
                  {
                      "color": "#363636"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "geometry.stroke",
              "stylers": [
                  {
                      "lightness": 29
                  },
                  {
                      "weight": 0.2
                  },
                  {
                      "color": "#363636"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "geometry",
              "stylers": [
                  {
                      "lightness": 18
                  },
                  {
                      "color": "#363636"
                  }
              ]
          },
          {
              "featureType": "road.local",
              "elementType": "geometry",
              "stylers": [
                  {
                      "lightness": 16
                  },
                  {
                      "color": "#363636"
                  }
              ]
          },
          {
              "featureType": "transit",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 19
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                  {
                      "color": "#000000"
                  },
                  {
                      "lightness": 17
                  }
              ]
          }
        ]
      };
    
      function initMain(mapDiv) {
        var map = {
          options: mapsOptions,
          center: new google.maps.LatLng(mapDiv.data('lat'), mapDiv.data('lng')),
          domObj: mapDiv.get(0)
        }
    
        map.map = new google.maps.Map(map.domObj, map.options);
        map.map.setCenter(map.center);
    
        map.marker = new google.maps.Marker({
          position: map.center,
          map: map.map,
          animation: google.maps.Animation.DROP,
          icon: {
            url: mapPinUrl,
            scaledSize: new google.maps.Size(72, 72),
            origin: new google.maps.Point(0,0)
          },
        });
      };
    
      google.maps.event.addDomListener(window, 'load', initMain($('#map')));

    });
  }
}

