import Swiper from 'swiper';

export default {
  init() {
    new Swiper ('.home-slider__container', {
      effect: 'slide',
      slidesPerView: 1,
      speed: 600,
      spaceBetween: 0,
      pagination: {
        el: '.home-slider__pagination',
        type: 'fraction',
      },
      navigation: {
        nextEl: '.home-slider__next',
        prevEl: '.home-slider__prev',
      }
    });
  }
}
