export default {
  init() {
    const tooltips = $('#tooltip');
    const tooltipFloor = tooltips.find('.tooltip__floor');
    const tooltipFlatsLeft = tooltips.find('.tooltip__flats-number');

    $( '[data-floor]' ).on( "mousemove", function( event ) {
      $( "#tooltip" ).css({
        "left" : event.clientX - 130,
        "top" : event.clientY + 20
      });
    });

    $('#floor-svg-list').find('polygon').each(function() {
      $(this).hover(function(){
        let floor = $(this).data('floor');
        let flatsLeft = building[floor];
        tooltipFloor.html(floor);
        tooltipFlatsLeft.html(flatsLeft.freeFlats);
        $( "#tooltip" ).css({
          "display" : 'block'
        });
      }, function() { 
        $( "#tooltip" ).css({
          "display" : 'none'
        });
      });
    });
  },
  initFloor() {
    const tooltip = $('#floor-tooltip');
    const tooltipRoom = tooltip.find('.tooltip__room');
    const tooltipStatus = tooltip.find('.tooltip__status');
    const tooltipRooms = tooltip.find('.tooltip__rooms-number');
    const tooltipArea = tooltip.find('.tooltip__area-number');

    $( '[data-room-id]' ).on( "mousemove", function( event ) {
      $( "#floor-tooltip" ).css({
        "left" : event.clientX - 130,
        "top" : event.clientY + 20
      });
    });

    $('.floor__map').find('[data-room-id]').each(function() {
      $(this).hover(function(){
        let floor = $('.floor-list').find('.act').data('floor-select');
        let room = $(this).data('room-id');
        let status, roomCount, areaCount;
        if(building[floor].flatIds[room]) {
          status = building[floor].flatIds[room].statusMsg;
          roomCount = building[floor].flatIds[room].rooms;
          areaCount = building[floor].flatIds[room].area;
        } else {
          status = "-";
          roomCount = "-";
          areaCount = "-";
        }
        tooltipRoom.html(room);
        tooltipStatus.html(status);
        tooltipRooms.html(roomCount);
        tooltipArea.html(areaCount);
        $( "#floor-tooltip" ).css({
          "display" : 'block'
        });
      }, function() { 
        $( "#floor-tooltip" ).css({
          "display" : 'none'
        });
      });
    });
  }
}
