export default {
  init() {
    $('.interior-tabs__button').on('click', function() {
      let target = $(this).data('btn');
      $(this).siblings().removeClass('act');
      $(this).addClass('act');
      $('.tab').addClass('hidden');
      $('[data-tab="' + target + '"]').removeClass('hidden');
    });
  }
}
