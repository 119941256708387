export default {
  init() {
    $("body").on("click", ".modal_close:not(.secondary)", (e) => {
      this.closeModal($(e.target).closest(".modal").get(0));
    });

    $("body").on("click", ".modal_close.secondary", (e) => {
      this.closeSecModal($(e.target).closest(".modal").get(0));
    });

    $("body").on('click', "[data-modal]", (e) => {
      this.showFilters(`#${$(e.currentTarget).data('filtergroup')}`);
      this.openModal(`.modal#${$(e.currentTarget).data('modal')}`);
      
      const passData = $(e.currentTarget).data('passdata');
      if (passData) {
        $(`.modal#${$(e.currentTarget).data('modal')}`).find(`input[name="${passData.name}"]`).val(passData.value);
      }
    });

    $("body").on("click", "[data-modal='reservation']", (e) => {
      let apartamentID = $('#apartament-number').html();
      $('[name="reservation-apartament"]').attr('value', apartamentID);
      console.log(apartamentID);
    });
  },
  floorModal() {
    $("body").on('click', "[data-floor]", (e) => {
      $('[data-floor-select]').removeClass('act');
      this.openModal(`.modal#floor-plan`);
      selectTaken($(e.currentTarget).data('floor'));
      $(`[data-floor-select="${$(e.currentTarget).data('floor')}"]`).addClass('act');
      $('.floor').hide();
      $(`#floor-plan-${$(e.currentTarget).data('floor')}`).fadeIn('fast');
    });

    $("body").on('click', "[data-floor-select]", (e) => {
      $('[data-floor-select]').removeClass('act');
      $(e.currentTarget).addClass('act');
      selectTaken($(e.currentTarget).data('floor-select'));
      $('.floor').hide();
      $(`#floor-plan-${$(e.currentTarget).data('floor-select')}`).fadeIn('fast');
    });

    function selectTaken(id) {
      const keys = Object.keys(building[id].flatIds);
      for (const key of keys) {
        if(building[id].flatIds[key].status == 0) {
          $(`[data-room-id=${key}]`).addClass('taken');
        }
      }
    }
  },
  openModal(modal) {
    $('body').addClass('fixed');
    $(modal).fadeIn(300, () => {
      $(modal).find('input:not([type=submit])').first().focus();
    });
  },
  closeSecModal(modal) {
    $(modal).fadeOut(300, function(){
      $('.filterGroup').hide();
    });
  },
  closeModal(modal) {
    $(modal).fadeOut(300, function(){
      $('body').removeClass('fixed');
      $('.filterGroup').hide();
    });
  },
  showFilters(filters) {
    $(filters).fadeIn(100);
  }
}
