export default {
  init() {

    // scroll down animation
    $('.scroll-down').on('click', function() {
      let wrapper = $(this).closest('section');
      let target = wrapper.next();
      $("html, body").animate({ scrollTop: target.offset().top - 80 }, "slow");
      return false;
    });

    // burger
    $('.burger').on('click', function() {
      $('.mobile-menu').addClass('open');
      $('body').addClass('fixed');
      $('.header').addClass('mob-opened');
    });

    $('.mobile-menu__close').on('click', function() {
      $('.mobile-menu').removeClass('open');
      $('body').removeClass('fixed');
      $('.header').removeClass('mob-opened');
    });

    // "more about project" hover 
    $('.about-project-link__wrap > a').hover(function() {
      $(this).parent().toggleClass('hover');
    });

    // tables wrap
    $('.text-content').find('table').each(function() {
      let icon = `
        <div class="scroll-icon only-mob">
          <div class="scroll-icon__anim">
          <svg xmlns="http://www.w3.org/2000/svg" width="41.414" height="35.707" viewBox="0 0 41.414 35.707"><g transform="translate(-44 -4522.293)"><path d="M20,10A10,10,0,0,1,0,10" transform="translate(45 4537)" fill="none" stroke="#131514" stroke-width="2"/><path d="M0,10a2,2,0,0,0,4,0V3" transform="translate(49 4550) rotate(180)" fill="none" stroke="#131514" stroke-width="2"/><path d="M0,10a2,2,0,0,0,4,0V-5" transform="translate(53 4542) rotate(180)" fill="none" stroke="#131514" stroke-width="2"/><path d="M0-4V10a2,2,0,0,0,4,0V-4" transform="translate(57 4539) rotate(180)" fill="none" stroke="#131514" stroke-width="2"/><path d="M4,3A2,2,0,0,1,0,3V-2" transform="translate(61 4541) rotate(180)" fill="none" stroke="#131514" stroke-width="2"/><path d="M4,4A2,2,0,0,1,0,4V-3" transform="translate(65 4544) rotate(180)" fill="none" stroke="#131514" stroke-width="2"/><path d="M1,11,6,6,1,1" transform="translate(78 4522)" fill="none" stroke="#131514" stroke-width="2"/><line x2="18" transform="translate(65 4528)" fill="none" stroke="#131514" stroke-width="2"/><path d="M5,11,0,6,5,1" transform="translate(64 4522)" fill="none" stroke="#131514" stroke-width="2"/><line x2="6" transform="translate(63 4530)" fill="none" stroke="#fff" stroke-width="2"/></g></svg>
          </div>
          Slinkite į šoną
        </div>
      `
      $(this).before(icon);
      $(this).wrap('<div class="tableOverflowWrap">');
    });

    // filter show hide
    let acc = document.getElementsByClassName("filters-toggle");
    let openedText =  $('.filters-toggle .text').data('opened');
    let closedText =  $('.filters-toggle .text').data('closed');
    let i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = document.getElementsByClassName("filters");
        if (panel[0].style.maxHeight) {
          panel[0].style.maxHeight = null;
        } else {
          panel[0].style.maxHeight = panel[0].scrollHeight + "px";
        }
      });
    }

    $('.filters-toggle').on('click', function() {
      if($(this).hasClass('active')) {
        $(this).find('.text').html(openedText);
      } else {
        $(this).find('.text').html(closedText);
      }
    });

  },
  scroll() {
    // header shrink
    if( $(window).width() > 768) {
      if($('#header').length > 0) {
        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
          document.getElementById("header").classList.add("shrink");
        } else {
          document.getElementById("header").classList.remove("shrink");
        }
      }
    }

    if ($('.figure:not(.found)').length > 0) {
      $('.figure:not(.found)').each(function(i, prod){
        var prodTop = $(prod).offset().top ;
        var mark = window.pageYOffset + (window.innerHeight*9/8);
  
        if (mark > prodTop) {
          $(prod).addClass('found');
        }
      });
    }

    if ($('[data-slide-in]:not(.found)').length > 0) {
      $('[data-slide-in]:not(.found)').each(function(i, prod){
        var prodTop = $(prod).offset().top ;
        var mark = window.pageYOffset + (window.innerHeight*9/8);
  
        if (mark > prodTop) {
          $(prod).addClass('found');
        }
      });
    }
  }
}


