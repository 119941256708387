export default {
  init() {
    var closeBtn = document.getElementById('cookieClose');
    if(closeBtn) {
      closeBtn.addEventListener('click', function(e){
        e.preventDefault();
        this.closest('.cookie').classList.add('closed');

        $.ajax({
          url: this.dataset.action,
          type: this.dataset.method,
          success(resp) {
            console.log(resp)
          }
        });
      });
    };
  }
}
