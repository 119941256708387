import { tranEv } from "./common";

export default {
  init() {
    
    $("[data-text]").on("change keyup keydown keypress", (e) => {
      if ($.trim(e.target.value).length > 0) {
        $(e.target).closest(".form_input").addClass('full');
      } else {
        $(e.target).closest(".form_input").removeClass('full');
      }
    });

    $(".form_input input").on("change keyup keydown keypress", (e) => {
      if ($(e.target).closest('.form_input').hasClass('error')) {
        $(e.target).closest(".form_input").removeClass('error').find('.error_msg').one(tranEv, function(){
          $(this).remove();
        });
      }
    });
  
    $("[data-text]").on('focusin', (e) => {
      $(e.target).closest(".form_input").addClass('focus');
    });
  
    $("[data-text]").on('focusout', (e) => {
      $(e.target).closest(".form_input").removeClass('focus');
    });

    $("[data-file]").on('change', (e) => {
      $(e.target).siblings('[data-text]').val(this.fileName($(e.target).val())).trigger('change');
    });

    $("[data-comment-file]").on('change', (e) => {
      let target = $('#attached-file-name');
      let fileName = this.fileName($(e.target).val());
      target.html(fileName);
      // $(e.target).siblings('[data-text]').val(this.fileName($(e.target).val())).trigger('change');
    });

    $("[data-async]").on('submit', (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      const $self = this;
      $(e.currentTarget).addClass('disabled');

      $.ajax({
        url: e.target.dataset.action,
        data: formData,
        processData: false,
        contentType: false,
        type: e.target.dataset.method,
        success(resp) {
          $(e.currentTarget).removeClass('disabled');
          $self.handleResponse(resp, e.target);
        }
      });
    });

    $('body').on('click', '.form_message .back:not(.form_close)', (e) => {
      $(e.currentTarget).closest('.form_message').fadeOut(300, function() {
        $(this).remove();
      });
    });

    $('body').on('click', '.form_message .back.form_close', (e) => {
      $(e.currentTarget).closest('.modal').fadeOut(300, function(){
        $('body').removeClass('fixed');
        $(e.currentTarget).closest('.form_message').remove();
      });
    });

    $('body').on('click', '.back.form_sec-close', (e) => {
      $(e.currentTarget).closest('.modal').fadeOut(300, function(){
        $(e.currentTarget).closest('.form_message').remove();
      });
    });
  },
  fileName(fullPath) {
    if (fullPath) {
      var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
      var filename = fullPath.substring(startIndex);
      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
      }
      return filename;
    } else {
      return '';
    }
  },
  handleResponse(resp, form) {
    if (resp.status === "badfields") {
      this.badFields(resp.fields, form);
    } else if (resp.status === "success-ask-form") {
      this.askForm(resp, form);
    } else if (resp.status === "success") {
      if (resp.redirect) {
        this.redirect(resp.redirect);
      }
      else {
        this.outputMessage(resp, form);
      }
    } else if (resp.status === "error") {
      this.outputMessage(resp, form);
    }
  },
  badFields(fields, form) {
    $(form).find('.error_msg').remove();
    fields.map((field, i) => {
      $(form).find(`input[name="${field.name}"]`).closest('.form_input').append(`<div class="error_msg">${field.error}</div>`);
      setTimeout(() => {
        $(form).find(`input[name="${field.name}"]`).closest('.form_input').addClass('error');
      }, 1 + (i * 200));
    });
  },
  redirect(url) {
    window.location.href = url;
  },
  askForm(resp, form) {
    const output = resp.output;
    const coverBlock = `
      <div class="form_submit__cover form_submit__cover-${resp.status}">
        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="25.83" height="20.536" viewBox="0 0 25.83 20.536"><path d="M-17940-301.585l1.412-1.414,5.295,5.29,1.414,1.414-1.414,1.418Zm8.121,2.463L-17917-314l1.418,1.414-14.881,14.876Z" transform="translate(17940.707 314.705)" fill="#131514" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/></svg></div>
        ${output.heading}
      </div>
    `;
    
    $(form).find('.form_submit').addClass('disabled').append(coverBlock).hide().fadeIn(300);
    setTimeout(function(){
      form.reset();
      $(form).find('.form_input').removeClass('full');
      $(form).find('.form_submit').removeClass('disabled');
      $(form).find('.form_submit__cover').remove();
    }, 4000);
  },
  outputMessage(resp, form) {
    const output = resp.output;
    let button =  '';

    if (output.button) {
      if (output.button.link === 'back') {
        button = `<div class="btn__pr btn__pr--pos back">
        <span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="25.623" height="14.827" viewBox="0 0 25.623 14.827"><path d="M-17937-304.291h2.828l2.291,2.292-1.414,1.414Zm.824-2H-17939l-1-1,6.707-6.7,1.414,1.414-4.295,4.291h20.59v2Z" transform="translate(17940.707 314.705)" fill="#131514" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/></svg></span>
        ${output.button.text}
        </div>`;
      } else if (output.button.link === 'close') {
        button = `<div class="btn__pr btn__pr--pos back form_close">
        <span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="24.003" viewBox="0 0 20 24.003"><path d="M357.92,570.119l-10,10v14h8v-10h4v10h8v-14Zm8,22h-4v-10h-8v8h0v4h-2v-2h-2V580.948l8-8,8,8Z" transform="translate(-347.92 -570.119)" fill="#131514"/></svg></span>
        ${output.button.text}
        </div>`;
      } else if (output.button.link === 'login') {
        button = `<div class="btn__pr btn__pr--pos back form_sec-close">
        <span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="20.998" height="24.998" viewBox="0 0 20.998 24.998"><path d="M-17940-290v-16h3v-1a7,7,0,0,1,7-7,7.009,7.009,0,0,1,6.926,6h-2.027a5,5,0,0,0-4.9-4,5,5,0,0,0-5,5v1h15v16Zm2-2h16v-12h-16Zm7-2v-2.184a3,3,0,0,1-2-2.818,3,3,0,0,1,3-3,3,3,0,0,1,3,3,3,3,0,0,1-2,2.818V-294Zm0-5a1,1,0,0,0,1,1,1,1,0,0,0,1-1,1,1,0,0,0-1-1A1,1,0,0,0-17931-299Z" transform="translate(17940.5 314.498)" fill="#131514" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/></svg></span>
        ${output.button.text}
        </div>`;
      } else if (output.button.link === 'over') {
        button = `<div class="btn__pr btn__pr--pos back">
        <span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="20.998" height="24.998" viewBox="0 0 20.998 24.998"><path d="M-17940-290v-16h3v-1a7,7,0,0,1,7-7,7.009,7.009,0,0,1,6.926,6h-2.027a5,5,0,0,0-4.9-4,5,5,0,0,0-5,5v1h15v16Zm2-2h16v-12h-16Zm7-2v-2.184a3,3,0,0,1-2-2.818,3,3,0,0,1,3-3,3,3,0,0,1,3,3,3,3,0,0,1-2,2.818V-294Zm0-5a1,1,0,0,0,1,1,1,1,0,0,0,1-1,1,1,0,0,0-1-1A1,1,0,0,0-17931-299Z" transform="translate(17940.5 314.498)" fill="#131514" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/></svg></span>
        ${output.button.text}
        </div>`;
      } else {
        button = `<a href="${output.button.link}">${output.button.text}</a>`;
      }
    }

    const message = `
      <div class="form_message form_message-${resp.status}">
        <div class="main-icon"></div>
        <h4 class="form_message__text">${output.heading}</h4>
        
        ${button}
      </div>
    `;

    if(resp.status == 'success') {
      form.reset();
    }

    $(form).find('input').removeClass('full').removeClass('focus');
    $(form).append(message).find('.form_message').css({ display: "flex" }).hide().fadeIn(300);
    // $(form).find('.form__titleIcon').hide();
  }
}
