import Swiper from "swiper";

let gallerySlider;

export default {
  init() {
    const $self = this;
    let lastOpened;
    $("body").on("click", "[data-apartament]", (e) => {
      let targetId = $(e.currentTarget).data("apartament");
      let deep = 0;
      $self.sendToAPI(targetId, lastOpened, deep);
      lastOpened = targetId;
    });

    $("body").on("click", "[data-room-id]:not(.taken)", (e) => {
      let targetId = $(e.currentTarget).data("room-id");
      $self.sendToAPI(targetId, lastOpened);
      lastOpened = targetId;
    });
  },
  sendToAPI(targetId, lastOpened, deep) {
    const $self = this;
    if (targetId != lastOpened) {
      $("#apartament-gallery").empty();
      $("#gallery-slider").empty();

      // var formData = new FormData();
      // formData.append('apartament_number', targetId);

      $.ajax({
        url: $("#apartament").data("action"),
        data: {
          apartament_number: targetId,
        },
        // processData: false,
        // contentType: false,
        type: $("#apartament").data("method"),
        beforeSend: function () {
          $("#preloader").fadeIn("fast");
        },
        complete: function () {
          $("#preloader").hide();
        },
        success(resp) {
          $self.handleResponse(resp, targetId, deep);
        },
      });
    } else {
      $("#apartament").fadeIn(300);
    }
  },
  handleResponse(resp, targetId, deep) {
    if (resp.status === "success") {
      const mainImage = `<div class="ap-modal__plan-img" style="background-image: url(${resp.drawing});"></div>`;
      const zoomText = resp.gallery.zoomtext;

      $("#apartament-number").html(targetId);
      $("#apartament-direction").attr("src", resp.direction);
      $("#apartament-floor").html(resp.floor);
      $("#apartament-area").html(resp.area);
      $("#apartament-rooms").html(resp.rooms);
      $("#apartament-parking").html(resp.parking);
      $("#apartament-included").html(resp.included);
      $("#apartament-included-sec").html(resp.included);
      $("#apartament-included-reserForm").html(resp.included);
      $("#apartament-draw").html(mainImage);
      $("#apartament-slogan").html(resp.slogan);
      $("#apartament-exerp").html(resp.exerp);
      $("#apartament-conditions").html(resp.conditions);

      let indexes = 0;
      resp.gallery.images.forEach((item) => {
        let statusClass = item.single == 1 ? "modal-gallery__item--half" : "";
        const imgBlock = `
          <div class="modal-gallery__item ${statusClass}" data-order="${indexes}">
            <div class="modal-gallery__img" style="background-image: url(${item.url});"></div>
            <div class="modal-gallery__overflow">
              <div class="inner">
                <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="73.914" height="73.916" viewBox="0 0 73.914 73.916"><path d="M-18055.99-4472.254a30.873,30.873,0,0,1-21.187,8.4,31.034,31.034,0,0,1-31-31,31.035,31.035,0,0,1,31-31,31.036,31.036,0,0,1,31,31,30.866,30.866,0,0,1-8.4,21.187l19.109,19.1-1.412,1.414Zm-50.186-22.6a29.034,29.034,0,0,0,29,29,29.036,29.036,0,0,0,29-29,29.037,29.037,0,0,0-29-29A29.034,29.034,0,0,0-18106.176-4494.854Zm28,12v-11h-11v-2h11v-11h2v11h11v2h-11v11Z" transform="translate(18108.676 4526.357)" fill="#131514" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/></svg></div>
                <div class="text">${zoomText}</div>
              </div>
            </div>
          </div>
        `;

        const swiperSlide = `
        <div class="gal-slider__slide swiper-slide" style="background-image: url(${item.url});"></div>
        `;

        indexes++;

        $("#apartament-gallery").append(imgBlock);
        $("#gallery-slider").append(swiperSlide);
      });

      gallerySlider.update();
      $("body").addClass("fixed");
      $('[data-btn="1"]').click();
      if (deep == 0) {
        $("#apartament").fadeIn(300).removeClass("secondary");
      } else {
        $("#apartament").fadeIn(300).addClass("secondary");
      }
    } else if (resp.status === "error") {
      console.log(resp.message);
    }
  },
  sliderInit() {
    gallerySlider = new Swiper(".gal-slider__container", {
      effect: "slide",
      slidesPerView: 1,
      speed: 600,
      spaceBetween: 30,
      pagination: {
        el: ".gal-slider__pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: ".gal-slider__next",
        prevEl: ".gal-slider__prev",
      },
      observer: true,
      observeParents: true,
    });

    $("body").on("click", ".modal-gallery__item", (e) => {
      let targetSlide = $(e.currentTarget).data("order");
      gallerySlider.slideTo(targetSlide);
      $("#gal-slider").fadeIn();
    });
  },
};
