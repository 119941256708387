import "../scss/style.scss";
import "../../node_modules/swiper/css/swiper.min.css";

import tabs from "./components/tabs.js";
import cookie from "./components/cookie.js";
import homeSlider from "./components/home-slider.js";
import modals from "./components/modals";
import form from "./components/form";
import tooltip from "./components/tooltip";
import sorting from "./components/sorting";
import apartamentModal from "./components/apartament-modal";
import profile from "./components/profle";
import smallEvents from "./components/small-events";
import map from "./components/map";
import imgFit from "./components/img-fit";

$(document).ready(function () {
  imgFit.init();
  tabs.init();
  cookie.init();
  homeSlider.init();
  modals.init();
  modals.floorModal();
  apartamentModal.init();
  apartamentModal.sliderInit();
  form.init();
  tooltip.init();
  tooltip.initFloor();
  if ($(".filters").length > 0) {
    sorting.sliders();
  }
  sorting.sortBtns();
  profile.trumbowyg();
  profile.init();
  smallEvents.init();
});

const onLoad = (e) => {
  onResize(e);
  if ($("#map").length > 0) {
    map.init();
  }
};

const onResize = (e) => {
  onScroll(e);
};

const onScroll = (e) => {
  smallEvents.scroll();
};

$(window).on("scroll", onScroll);
$(window).on("resize", onResize);
$(window).on("load", onLoad);
