import '../plugins/jquery-ui-onlySlider/jquery-ui';
import '../plugins/touch-punch';
import '../plugins/jquery-ui-onlySlider/jquery-ui.css';

export default {
  sliders() {
    const $self = this;
    let floorValHolders;
    let floorMin = $("#slider-floor").data('min');
    let floorMax = $("#slider-floor").data('max');
    let areaMin = $("#slider-area").data('min');
    let areaMax = $("#slider-area").data('max');
    let roomsMin = $("#slider-rooms").data('min');
    let roomsMax = $("#slider-rooms").data('max');
    $( "#slider-floor" ).slider({
      range: true,
      min: floorMin,
      max: floorMax,
      values: [ floorMin, floorMax ],
      slide: function( event, ui ) {
        floorValHolders[0].dataset.val = ui.values[ 0 ];
        floorValHolders[1].dataset.val = ui.values[ 1 ];
      }, 
      stop: function() {
        $self.sendToAPI();
      }
    });
    $("#slider-floor").find('span').append('<div class="value-holder" data-val></div>');
    floorValHolders = $("#slider-floor").find('.value-holder');
    floorValHolders[0].dataset.val = $( "#slider-floor" ).slider( "values", 0 );
    floorValHolders[1].dataset.val = $( "#slider-floor" ).slider( "values", 1 );

    let areaValHolders;
    $( "#slider-area" ).slider({
      range: true,
      min: areaMin,
      max: areaMax,
      values: [ areaMin, areaMax ],
      slide: function( event, ui ) {
        areaValHolders[0].dataset.val = ui.values[ 0 ];
        areaValHolders[1].dataset.val = ui.values[ 1 ];
      }, 
      stop: function() {
        $self.sendToAPI();
      }
    });
    $("#slider-area").find('span').append('<div class="value-holder" data-val></div>');
    areaValHolders = $("#slider-area").find('.value-holder');
    areaValHolders[0].dataset.val = $( "#slider-area" ).slider( "values", 0 );
    areaValHolders[1].dataset.val = $( "#slider-area" ).slider( "values", 1 );

    let roomsValHolders;
    $( "#slider-rooms" ).slider({
      range: true,
      min: roomsMin,
      max: roomsMax,
      values: [ roomsMin, roomsMax ],
      slide: function( event, ui ) {
        roomsValHolders[0].dataset.val = ui.values[ 0 ];
        roomsValHolders[1].dataset.val = ui.values[ 1 ];
      }, 
      stop: function() {
        $self.sendToAPI();
      }
    });
    $("#slider-rooms").find('span').append('<div class="value-holder" data-val></div>');
    roomsValHolders = $("#slider-rooms").find('.value-holder');
    roomsValHolders[0].dataset.val = $( "#slider-rooms" ).slider( "values", 0 );
    roomsValHolders[1].dataset.val = $( "#slider-rooms" ).slider( "values", 1 );

  },
  sortBtns() {

    $('body').on('click', '#only_free', (e) => {
      this.sendToAPI();
    });
    
    $("body").on('click', "[data-sorter]", (e) => {
      $('[data-sorter]').removeClass('act');
      $(e.currentTarget).addClass('act');
      if($(e.currentTarget).data('sort-direction') == "") {
        $('[data-sorter]').data('sort-direction', '');
        $('[data-sorter]').attr('data-sort-direction', '');
        $(e.currentTarget).data('sort-direction', 'asc');
        $(e.currentTarget).attr('data-sort-direction', 'asc');
      } else if($(e.currentTarget).data('sort-direction') == "asc") {
        $(e.currentTarget).data('sort-direction', 'desc');
        $(e.currentTarget).attr('data-sort-direction', 'desc');
      } else if($(e.currentTarget).data('sort-direction') == "desc") {
        $(e.currentTarget).data('sort-direction', 'asc');
        $(e.currentTarget).attr('data-sort-direction', 'asc');
      } 
      this.sendToAPI();
    });
  },
  sendToAPI() {
    const $self = this;

    $.ajax({
      url: $('#filters').data('action'),
      data:  {
        sort_by: $('[data-sorter].act').data('sorter'),
        sort: $('[data-sorter].act').data('sort-direction'),
        min_floors: $( "#slider-floor" ).slider( "values", 0 ),
        max_floors: $( "#slider-floor" ).slider( "values", 1 ),
        min_area: $( "#slider-area" ).slider( "values", 0 ),
        max_area: $( "#slider-area" ).slider( "values", 1 ),
        min_rooms: $( "#slider-rooms" ).slider( "values", 0 ),
        max_rooms: $( "#slider-rooms" ).slider( "values", 1 ),
        only_free: $('[name="only_free"]').is(":checked") ? 1 : 0
      },
      type: $('#filters').data('method'),
      beforeSend: function(){
        $('#apartament-list').addClass('loading');
      },
      complete: function(){
          $('#apartament-list').removeClass('loading');
      },
      success(resp) {
        $self.handleResponse(resp);
      }
    });
  },
  handleResponse(resp) {

    if (resp.status === "success") {
      let roomList = $('#room-list');
      roomList.empty();

      resp.list.forEach(item => {
        let statusClass = item.status == 0 ? 'taken' : '';
        const roomSingle = `
          <div class="list-body__row ${statusClass}" data-apartament="${item.id}">
              <div class="container">
                <div class="list-body__item list-body__item--nr">
                  <p>${item.id}</p>
                </div>
                <div class="list-body__item">
                  <p>${item.floor}</p>
                </div>
                <div class="list-body__item">
                  <p>${item.area}</p>
                </div>
                <div class="list-body__item">
                  <p>${item.direction}</p>
                </div>
                <div class="list-body__item">
                  <p>${item.rooms}</p>
                </div>
                <div class="list-body__item availability">
                  <p>${item.statusMsg}</p>
                </div>
              </div>
            </div>
          `;

        roomList.append(roomSingle);

      });
    } else if(resp.status === "error") {
      console.log(resp.message);
    }
  },
}
